html, body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Arial", "sans-serif";
  font-weight: 300;
}

/*
Strongest sky blue: 9ac7e2
gradient sky to purple: 9e9eee
medium royal blue: 283463
dark royal blue: 1E264A
light purple: 9B9BDE
*/

.App {
  padding: 8px 16px;
}

.header {
  color: #283463;
  font-family: "Nunito", "Open Sans", "Helvetica", "Sans Serif";
  font-size: 56pt;
  line-height: 1.0;
  margin-bottom: 16vh;
  text-shadow: 1px 1px 1px rgba(223, 223, 255, 0.6);
}
.subHeader {
  color: #1e254A;
  font-size: 14pt;
  font-weight: 200;
  margin-bottom: 8pt;
}

.separator {
  height:0;
}

.link {
  color: #3d69c7;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

.link:hover {
  color: #6969e0;
  cursor: pointer;
  text-shadow: 1px 1px 2px white;
}

@media(max-width: 420px) {
  .header {
    font-size: 48pt;
    margin-bottom: 12vh;
  }
}